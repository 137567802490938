import { closeToast, showFailToast, showLoadingToast } from 'vant';

import { login } from '@eaphone/auth';
import { Auth, Temp } from '@eaphone/storage';

import { getPhoneNumber } from './hejia-sdk.js';

async function loginFromPhone() {
  const code = await getPhoneNumber();

  return login({ code, type: 'hejiaqin' });
}

export function loginHook(to, from, next) {
  const { deviceId } = to.query;

  if (deviceId) {
    Temp.deviceId = deviceId;
  }

  if (to.meta.auth && !Auth.token) {
    showLoadingToast('正在登录...');
    loginFromPhone()
      .then(() => {
        closeToast();
        next({ path: to.fullPath, replace: true });
      })
      .catch((error) => {
        console.error(error);
        showFailToast(error.message);
        next({ name: 'error-401' });
      });
  } else {
    next(); // 不做任何处理
  }
}

export function usePageConfig() {}
