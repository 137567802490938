import { Temp } from '@eaphone/storage';

const { Hejia } = window;

class SdkError extends Error {
  constructor(message = "Can't found Hejia SDK") {
    super(message);
    this.name = 'SdkError';
  }
}

function Promisify(action) {
  let auto;

  return new Promise((resolve, reject) => {
    if (Hejia === undefined) {
      reject(new SdkError());
    } else {
      Hejia.ready(() => {
        clearTimeout(auto);
        action(resolve, reject);
      });
      auto = setTimeout(() => {
        reject(new SdkError());
      }, 5000);
    }
  });
}

export async function getDeviceID() {
  const { deviceId } = Temp;

  if (deviceId) {
    return deviceId;
  }

  throw new SdkError('no device id');
}

export function getPhoneNumber() {
  return Promisify((resolve, reject) => {
    Hejia.getPhoneNumber(
      (phone) => {
        if (/^1\d{10}$/.test(phone)) {
          resolve(phone);
        } else {
          reject(new SdkError('手机号不正确'));
        }
      },
      (msg, ...args) => {
        console.warn('getPhoneNumber', msg, ...args);
        reject(new SdkError(msg));
      },
    );
  });
}
